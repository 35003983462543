/* Material Design variables */
:root {
  --grey-900: #202124;
  --grey-800: #3c4043;
  --grey-700: #5f6368;
  --grey-650: #6f757a;
  --grey-600: #80868b;
  --grey-500: #9aa0a6;
  --grey-400: #bdc1c6;
  --grey-300: #dadce0;
  --grey-200: #e8eaed;
  --grey-100: #f1f3f4;
  --grey-50: #f8f9fa;
  --blue-900: #174ea6;
  --blue-800: #185abc;
  --blue-700: #1967d2;
  --blue-600: #1a73e8;
  --blue-500: #4285f4;
  --blue-400: #669df6;
  --blue-300: #8ab4f8;
  --blue-200: #aecbfa;
  --blue-100: #d2e3fc;
  --blue-50: #e8f0fe;
  --red-900: #a50e0e;
  --red-800: #b31412;
  --red-700: #c5221f;
  --red-600: #d93025;
  --red-500: #ea4335;
  --red-400: #ee675c;
  --red-300: #f28b82;
  --red-200: #f6aea9;
  --red-100: #fad2cf;
  --red-50: #fce8e6;
  --yellow-900: #e37400;
  --yellow-800: #ea8600;
  --yellow-700: #f29900;
  --yellow-600: #f9ab00;
  --yellow-500: #fbbc04;
  --yellow-400: #fcc934;
  --yellow-300: #fdd663;
  --yellow-200: #fbbc04;
  --yellow-100: #feefc3;
  --yellow-50: #fef7e0;
  --green-900: #0d652d;
  --green-800: #137333;
  --green-700: #188038;
  --green-600: #1e8e3e;
  --green-500: #34a853;
  --green-400: #5bb974;
  --green-300: #81c995;
  --green-200: #a8dab5;
  --green-100: #ceead6;
  --green-50: #e6f4ea;
  --orange-100: #feefe3;
  --orange-200: #fedfc8;
  --orange-300: #fdc69c;
  --orange-400: #fcad70;
  --orange-500: #fa903e;
  --orange-600: #fa7b17;

  --bg-grid-line: #5f636866;
  --bg-skeleton: #5f636866;
  --max-width: 1200px;

  /* --bg-page: #061a2e;
  --color-text: #fff;
  --color-accent: #ff786a; */

  --bg-page: #111119;
  --color-text: #fff;
  --color-text-third: rgba(255, 255, 255, 0.3);
  --color-accent: #fff;

  --font-family-primary: sans-serif;
  --font-family-secondary: "Google Sans";
  --bg-active: var(--grey-100);
  --border: var(--grey-200);
  --pad-x: 20px;
  --padding-y-section: 20px;
  --image-filter-mode: "lighten";
}

:root [data-theme="1"] {
  --bg-page: #061a2e;
  --color-text: #fff;
  --color-accent: #ff786a;
}

:root [data-theme="2"] {
  --bg-page: #ffffff;
  --color-text: #3a3a3a;
  --color-accent: #3a3a3a;
  --color-text-third: rgba(25, 25, 25, 0.3);
}

:root [data-theme="3"] {
  --bg-page: #283f3b;
  --color-text: #ffffff;
  --color-accent: #9dcfc0;
}

img {
  mix-blend-mode: lighten;
}

[data-theme="2"] img {
  mix-blend-mode: darken;
}
